import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";
import * as siteActions from "../../redux/Site/actions";
import { Header, Sticky, ButtonHome } from "../../components/General"
import InputSearch from "../../components/Input/Search"
import SiteDetails from "../../components/Site/Details"
import ModalLocation from "../../components/Modals/Location"
import { Site } from "../../models/Interfaces/Site";
import { showErrorNotification } from "../../utils/Notification";
import { AppState } from "../../redux/reducers";
import { useThunkDispatch } from "../../redux/hooks";

interface Props { }

const Home = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [searchSites, setSearchSites] = useState([] as Site[]);
  const [selectedSite, setSelectedSite] = useState()
  const [showModalLocation, setShowModalLocation] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  // pull out state from our store
  const { sites, error } = useSelector((state: AppState) => ({
    sites: state.site.sites,
    error: state.site.error
  }));
  
  const handleListSites = () => {
    if (navigator.onLine) {
      setLoading(true);
      dispatch(siteActions.getSitesDispatch({}))
        .then(res => {
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          showErrorNotification(
            "Une erreur s'est produite lors du chargement des chantiers"
          );
      });
    } else {
      dispatch(siteActions.getSitesDispatch({}));
    }
  };

  useEffect(() => {
    handleListSites();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (sites) {
      if (searchValue.length > 0) {
        const values = sites.filter(site => {
          try {
            const name = site.name.toLowerCase()
            const clientName = site.client.name.toLowerCase()
            const projectName = site.project ? site.project.name ? site.project.name.toLowerCase() : null : null
            return name.search(searchValue) != -1 ||
              clientName.search(searchValue) != -1 ||
              (projectName && projectName.search(searchValue) != -1)
          }
          catch (e) {
            return false
          }
        })
        setSearchSites(values)
      } else {
        setSearchSites(sites)
      }
    }
  }, [sites, searchValue])

  const handleShowModalLocation = () => {
    setShowModalLocation(!showModalLocation)
  }

  const IconHeader = <ButtonHome onClick={() => {
    history.push({
      pathname: '/dashboard',
      state: { transition: "slideRight" }
    });
  }}></ButtonHome>

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
      <Sticky className="bg-white">
        <Header icon={IconHeader} shadow>
          <div className="ml-10 mr-auto">
            <InputSearch setSearch={setSearchValue} placeholder="Rechercher un chantier" />
          </div>
        </Header>
      </Sticky>
      <ul>
        {searchSites &&
          searchSites?.map((site, index) => {
            let className =
              index % 2 === 0
                ? "bg-socotec-white-100 cursor-pointer"
                : "bg-white cursor-pointer";


            const siteHasMissions = !_.isEmpty(site.missions);
            console.log({
              "siteHasMissions :: ": siteHasMissions
            });

            const handleSelectedSite = () => {
              setSelectedSite(site)
              handleShowModalLocation()
            }

            return (
              <li
                key={index}
                className={className}
              >
                <SiteDetails site={site} onClickLocation={handleSelectedSite} />
              </li>
            );
          })}
      </ul>

      {_.isEmpty(sites) && !loading && !error && (
        <div className="flex flex-col justify-center flex-grow">
          <div className="flex flex-col -mt-10">
            <span className="italic text-center font-roboto text-socotec-gray-100 font-bold">
              {intl.messages["HomePage.noResults"]}
            </span>
          </div>
        </div>
      )}

      {_.isEmpty(sites) && !loading && error && (
        <div className="flex flex-col justify-center flex-grow">
          <div className="flex flex-col -mt-10">
            <span className="italic text-center font-roboto text-socotec-gray-100 font-bold">
              Une erreur s'est produite, veuillez actualiser la page
            </span>
          </div>
        </div>
      )}

      {selectedSite && (
        <ModalLocation
          site={selectedSite}
          isOpen={showModalLocation}
          onClick={handleShowModalLocation}
          closeTimeoutMS={500}
        />
      )}
    </div>
  );
};

export default injectIntl(Home);
