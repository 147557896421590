import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import { useIntl } from "react-intl";
import {
  showSuccessNotification,
  showErrorNotification
} from "../../../utils/Notification";
import images from "../../../images";
import * as missionActions from "../../../redux/Mission/actions";
import { Site } from "../../../models/Interfaces/Site";
import { Mission } from "../../../models/Interfaces/Mission";
import ModalHeader from "../ModalHeader"
import ModalClose from "../ModalClose"
import ModalContent from "../ModalContent"
import ModalConfirm from "../Confirm"
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { useThunkDispatch } from "../../../redux/hooks";

interface Props {
  site: Site;
  mission: Mission;
  isOpen: boolean;
  onClick: () => void;
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#F5F7FC"
  },
  overlay: {
    zIndex: 99999
  }
};

const ModalComment = (props: Props) => {
  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const [comment, setComment] = useState(props.mission.comment);
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const handleUpdateMission = () => {
    if (props.site.id) {
      const payload = {
        id: props.mission.id,
        comment: comment,
        site: props.site
      };

      if (navigator.onLine) {
        dispatch(missionActions.updateMissionDispatch(payload, props.mission))
          .then(res => {
            showSuccessNotification("Le commentaire a été ajouté avec succès");
          })
          .catch(err => {
            showErrorNotification("Le commentaire n'a pas été ajouté");
          });
        props.onClick();
      } else {
        dispatch(missionActions.updateMissionDispatch(payload, props.mission));
        props.onClick();
      }
    }
  };

  const handleCommentChange = e => {
    setComment(e.target.value);
  };

  const handleShowModalConfirm = () => {
    setShowModalConfirm(!showModalConfirm)
  }

  const closeModal = <ModalClose icon={images.arrowLeft} onClick={handleShowModalConfirm} />

  const addonRight = <div onClick={handleUpdateMission} className="absolute right-20px self-center text-socotec-blue-100 text-15px">Enregistrer</div>

  // @todo: i18n
  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-comment"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <ModalHeader sticky title={intl.messages["ModalComment.title"]} closeButton={closeModal} addonRight={addonRight} />

      <ModalContent>
        <div className="flex flex-col">
          <div className="font-bold text-socotec-gray-100 mb-4 text-18px">{intl.messages["ModalComment.comment"]}</div>
          <textarea
            value={comment}
            onChange={handleCommentChange}
            placeholder="J'écris mon commentaire ici"
            cols={40}
            rows={10}
            className="w-full font-roboto tracking-normal text-16px bg-socotec-white-100 text-socotec-gray-100 border-none outline-none"
          />
        </div>
      </ModalContent>

      <ModalConfirm
        isOpen={showModalConfirm}
        onValid={() => {
          handleShowModalConfirm()
          handleUpdateMission()
        }}
        onCancel={() => {
          setComment(props.mission.comment)
          handleShowModalConfirm()
          props.onClick()
        }}
        textValid={intl.messages["ModalComment.modal.confirm.button.start"]}
        textCancel={intl.messages["ModalComment.modal.confirm.button.cancel"]}
      />
    </ReactModal>
  );
};

export default ModalComment;
