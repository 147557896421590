import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, useIntl } from "react-intl";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import images from "../../images";
import * as siteActions from "../../redux/Site/actions";
import { showErrorNotification } from "../../utils/Notification";
import Button from "../../components/Button";
import { AppState } from "../../redux/reducers";
import { useThunkDispatch } from "../../redux/hooks";

const ReportConfirmation = () => {
  const { id, mid } = useParams();
  console.log({ "id :": id, "mid:": mid });

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  // pull out state from our store
  const { site, error } = useSelector((state: AppState) => ({
    site: state.site.site,
    error: state.site.error
  }));

  useEffect(() => {
    console.log("useEffect didmount");
    handleGetSite();
    return () => {
      console.log("useEffect willunmount");
    };
  }, []);

  const handleGetSite = () => {
    if (id) {
      console.log("fetch site by id");

      dispatch(siteActions.getSiteDispatch({ id: id }))
        .then(res => {
          console.log("Fetch site Success");
        })
        .catch(err => {
          console.log("Fetch site Failure");
          showErrorNotification(
            "Une erreur s'est produite lors du chargement du chantier"
          );
        });
    }
  };

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
      <div className="flex flex-col bg-socotec-blue-100">
        {/* title */}
        <div className="flex flex-row py-4">
          <div className="flex-1 flex flex-col">
            <span className="text-center text-white font-roboto font-bold text-16px tracking-normal opacity-100">
              {intl.messages["ReportConfirmationPage.title"]}
            </span>
          </div>
        </div>

        {/* site ref */}
        <div className="flex flex-row pt-4 pb-0">
          <div className="flex-1 flex flex-col">
            <span className="text-center font-roboto text-white text-13px tracking-13px opacity-100 uppercase">
              {intl.messages["ReportConfirmationPage.ref"]}{" "}
              {site && site.reference}
            </span>
          </div>
        </div>

        {/* site name */}
        <div className="flex flex-row mb-8">
          <div className="flex-1 flex flex-col items-center">
            {site && (
              <span className="text-center font-roboto text-white font-bold text-30px tracking-03px opacity-100 w-6/12">
                {site.name}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-grow bg-white justify-between">
        <div className="flex flex-col items-center my-10">
          <img src={images.noun} alt="" className="" />
          <span className="font-roboto text-socotec-gray-100 tracking-016px text-16px opacity-100 mt-4">
            {intl.messages["ReportConfirmationPage.send"]}
          </span>
        </div>
        <div className="flex flex-col mx-10 my-10">
          <Button
            onClick={() => {
              history.push(`/home`);
            }}
          >
            <span className="font-roboto">
              {intl.messages["ReportConfirmationPage.home"]}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ReportConfirmation);
