import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";
import images from "../../images";
import * as authenticationAction from "../../redux/Authentication/actions"
import * as siteActions from "../../redux/Site/actions";
import { showErrorNotification } from "../../utils/Notification";
import { AppState } from "../../redux/reducers";
import MissionStatus from "../../enums/mission"
import SiteDetails from "../../components/Site/Details"
import ModalAccount from "../../components/Modals/Account";
import "./style.css"
import { useThunkDispatch } from "../../redux/hooks";

interface Props { }

const Dashboard = (props: Props) => {
  const [showModalAccount, setShowModalAccount] = useState(false)

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  // pull out state from our store
  const { sites } = useSelector((state: AppState) => ({
    sites: state.site.sites
  }));

  const user = useSelector((state: AppState) => state.authentication.user);

  useEffect(() => {
    handleListSites();
  }, []);

  const handleListSites = () => {
    if (navigator.onLine) {
      dispatch(siteActions.getSitesDispatch({}))
        .then(res => {
        })
        .catch(err => {
          showErrorNotification(
            "Une erreur s'est produite lors du chargement des chantiers"
          );
        });
    } else {
      dispatch(siteActions.getSitesDispatch({}));
    }
  };

  const handleDeconnexion = () => {
    dispatch(authenticationAction.signOut())
    history.push('/login')
  }

  const handleModalAccount = () => {
    setShowModalAccount(!showModalAccount)
  }

  const pendingMissions = sites?.filter(site => {
    return !_.isEmpty(site.missions.filter(mission => mission.status == MissionStatus.PENDING && mission.author?.id === user.id))
  })

  const numberOfSite = sites.length
  const chantiers = numberOfSite > 1 ? "chantiers" : "chantier"
  let userRole = ""
  switch (user.role) {
    case "ROLE_AGENT":
      userRole = "Intervenant";
      break;
    case "ROLE_PROJECT_MANAGER":
      userRole = "Pilote";
      break;
    case "ROLE_ADMIN":
      userRole = "Administrateur";
      break;
  }

  return (
    <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
      <div className="flex flex-col bg-socotec-blue-100 pt-4 pb-70px">
        <div className="flex flex-row justify-between px-4">
          <span className="font-roboto text-white text-15px tracking-016px opacity-100" onClick={handleDeconnexion}>
            {intl.messages["DashboardPage.button.deconnexion"]}
          </span>
          <span className="font-roboto text-white tracking-016px opacity-100" onClick={handleModalAccount}>
            {intl.messages["DashboardPage.button.compte"]}
          </span>
        </div>
        <div className="flex flex-row justify-center mt-10">
          <img src={images.intervenant} alt="" />
        </div>
        <div className="flex flex-row justify-center mt-4">
          <span className="font-roboto text-white text-18px tracking-normal opacity-100">
            {user.firstname} {user.lastname}
          </span>
        </div>
        <div className="flex flex-row justify-center mt-1">
          <span className="font-roboto uppercase text-white text-13px tracking-078px opacity-100">
            {userRole}
          </span>
        </div>
      </div>
      <div className="bg-white offset-negative p-20px mb-8 mx-4 rounded-lg shadow-lg">
        <div className="flex flex-row items-center justify-between" onClick={() => {
          history.push({
            pathname: "/home",
            state: { transition: "slideLeft" }
          })
        }}>
          <div>
            <div className="text-socotec-gray-100 text-18px">{intl.messages["DashboardPage.title.mesVisites"]}</div>
            <div className="text-socotec-blue-100 text-30px font-bold">{numberOfSite} {intl.messages["DashboardPage." + chantiers]}</div>
          </div>
          <div><img src={images.arrowRight} alt=">" /></div>
        </div>
      </div>
      <div>
        <div className="text-socotec-gray-800 text-13px uppercase tracking-078px mx-4">{intl.messages["DashboardPage.title.visiteToClose"]}</div>
        {pendingMissions && (
          <div>
            {pendingMissions.map((site, index) => {
              let className =
                index % 2 === 0
                  ? "bg-socotec-white-100 cursor-pointer"
                  : "bg-white cursor-pointer";

              const currentPendingMission = _.findLast(site.missions, mission => mission.status == MissionStatus.PENDING && mission.author && mission.author.id == user.id)

              return <SiteDetails key={site.id} site={site} className={className} onClickLocation={() => {
                history.push('/site/' + site.id + '/' + currentPendingMission.id)
              }} />
            })}
          </div>
        )}
      </div>
      {
        user && (
          <ModalAccount
            user={user}
            isOpen={showModalAccount}
            onClick={handleModalAccount}
          />
        )
      }
    </div>
  );
};

export default injectIntl(Dashboard);
