import * as actions from "./actionTypes";
import { SignInOpts } from "../../models/Authentication";
import { _refreshAccessToken } from "../../utils/Session";
import instance from "../../api";

// const BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;
const OAUTH_URL = process.env.REACT_APP_OAUTH_BACKEND_URL;

export const signInAndFetchUser = (credentials: SignInOpts) => {
  console.log("ENTRY  signInAndFetchUser");
  return (dispatch, getState) => {
    return dispatch(signIn(credentials)).then(res => {
      console.log({ "SIGNIN RESPONSE ::": res });
      const token = getState().authentication.token;
      return dispatch(getMe(token));
    });
  };
};

const signIn = (credentials: SignInOpts): actions.SignInAction => {
  return {
    type: actions.SIGN_IN,
    credentials,
    meta: {
      offline: {
        effect: {
          url: `${OAUTH_URL}/keycloak/token`,
          method: "POST",
          json: credentials
        },
        commit: {
          type: actions.SIGN_IN_SUCCESS,
          meta: { credentials: credentials }
        },
        rollback: {
          type: actions.SIGN_IN_FAILURE,
          meta: { credentials: credentials }
        }
      }
    }
  };
};

const getMe = (token: string): actions.GetMeAction => {
  console.log("ENTRY  getMe");
  return {
    type: actions.GET_ME,
    meta: {
      offline: {
        effect: {
          url: `${OAUTH_URL}/keycloak/user`,
          method: "GET",
          headers: {
            Authorization: token
          },
          refreshAccessToken: _refreshAccessToken
        },
        commit: {
          type: actions.GET_ME_SUCCESS
        },
        rollback: {
          type: actions.GET_ME_FAILURE
        }
      }
    }
  };
};

export const signOut = (): actions.SignOutAction => {
  return {
    type: actions.SIGN_OUT
  };
};

export const refreshToken = () => {
  return (dispatch, getState) => {
    const token = getState().authentication.refresh_token;
    dispatch({ type: actions.REFRESH_TOKEN });
    instance.post(`${OAUTH_URL}/keycloak/token/refresh`, { refresh_token: token }).then(
      response =>
        dispatch({
          type: actions.REFRESH_TOKEN_SUCCESS,
          payload: response.data
        }),
      error => dispatch({ type: actions.REFRESH_TOKEN_FAILURE, error: error })
    );
  };
};
