import { Reducer } from "redux";
import moment from "moment";
import * as actions from "./actionTypes";
import { Mission } from "../../models/Interfaces/Mission";
import { ErrorResponse } from "../../api/error";

export interface MissionState {
  mission: Mission | null;
  error: ErrorResponse | null;
}

const initialState: MissionState = {
  mission: null,
  error: null
};

const missionReducer: Reducer<MissionState, actions.MissionAction> = (
  state: MissionState = initialState,
  action: actions.MissionAction
): MissionState => {
  switch (action.type) {
    case actions.CREATE_MISSION:
      console.log({ "CREATE_MISSION action :: ": action });
      const mission = {
        ...action.payload.mission,
        id: action.payload.localId,
        created_at: moment().format(),
        updated_at: moment().format(),
        temp: true
      };

      // set mission id
      localStorage.setItem("mission_id", mission.id);

      return {
        ...state,
        mission,
        error: null
      };

    case actions.CREATE_MISSION_SUCCESS:
      console.log({ "CREATE_MISSION_SUCCESS action ::": action });

      // set mission id
      localStorage.setItem("mission_id", action.payload.id);

      return {
        ...state,
        mission: action.payload,
        error: null
      };

    // server fails to respond
    case actions.CREATE_MISSION_FAILURE:
      console.log({ "CREATE_MISSION_FAILURE action :: ": action });

      localStorage.removeItem("mission_id");

      return {
        ...state,
        mission: null,
        error: action.payload.response
      };

    case actions.UPDATE_MISSION:
      console.log({ "UPDATE_MISSION action ::": action });
      return {
        ...state,
        mission: {
          ...action.payload,
          updated_at: moment().format(),
          temp: true
        },
        error: null
      };

    case actions.UPDATE_MISSION_SUCCESS:
      console.log({ "UPDATE_MISSION_SUCCESS action ::": action });
      return {
        ...state,
        mission: action.payload,
        error: null
      };

    // server fails to respond
    case actions.UPDATE_MISSION_FAILURE:
      console.log({ "UPDATE_MISSION_FAILURE action :: ": action });
      return {
        ...state,
        mission: null,
        error: action.payload.response
      };

    default:
      return state;
  }
};

export default missionReducer;
