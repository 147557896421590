import { combineReducers } from "redux";
import authenticationReducer from "./Authentication/reducer";
import siteReducer from "./Site/reducer";
import workerReducer from "./Worker/reducer";
import missionReducer from "./Mission/reducer";
import * as workerActions from "./Worker/actionTypes";
import * as missionActions from "./Mission/actionTypes";
import * as siteActions from "./Site/actionTypes";
import { sortWorkersByDate, sortMissionsByDate } from "../utils/Sort";

const appReducer = combineReducers({
  authentication: authenticationReducer,
  site: siteReducer,
  worker: workerReducer,
  mission: missionReducer
});

function crossSliceReducer(state, action) {
  switch (action.type) {
    case workerActions.GET_WORKERS: {
      console.log({ "CROSS => GET_WORKERS action :: ": action });

      // get workers for a site
      const site = state.site.sites.find(e => e.id == action.payload.site.id);
      if (site) {
        // site found is array
        state.worker.workers = site.workers ? site.workers : [];
      }

      state.worker.workers = sortWorkersByDate(state.worker.workers)

      return {
        ...state,
        worker: state.worker
      };
    }

    case workerActions.GET_WORKERS_SUCCESS: {
      console.log({ "CROSS => GET_WORKERS_SUCCESS action :: ": action });

      // find index
      const index = state.site.sites.findIndex(
        e => e.id == action.meta.site.id
      );

      if (index >= 0) {
        // find site and add worker to this site
        const site = state.site.sites[index];

        // set workers in site
        site.workers = action.payload;

        // replace site in array
        state.site.site = site;
        state.site.sites[index] = site;

        return {
          ...state,
          site: state.site
        };
      } else {
        return {
          ...state
        };
      }
    }

    case workerActions.CREATE_WORKER: {
      console.log({ "CROSS => CREATE_WORKER action :: ": action });

      // get created worker in state
      const worker = state.worker.worker;
      console.log({ "CROSS => CREATE_WORKER worker :: ": worker });

      if (worker.site) {
        // find site index
        const index = state.site.sites.findIndex(e => e.id == worker.site.id);

        // find site and add worker to this site
        const site = state.site.sites[index];
        console.log({ "CROSS => CREATE_WORKER add worker to site :: ": site });

        site.workers?.push(worker);

        // sort workers
        site.workers = sortWorkersByDate(site.workers);

        state.site.site = site;
        state.site.sites[index] = site;

        console.log({
          "CROSS => CREATE_WORKER site state :: ": state.site
        });

        return {
          ...state,
          site: state.site
        };
      } else {
        return {
          ...state
        };
      }
    }

    case workerActions.CREATE_WORKER_FAILURE: {
      console.log({ "CROSS => CREATE_WORKER_FAILURE action :: ": action });
      return {
        ...state
      };
    }

    case workerActions.UPDATE_WORKER: {
      console.log({ "CROSS => UPDATE_WORKER action :: ": action });

      // get updated worker in state
      const worker = state.worker.worker;
      console.log({ "worker to update :::::::": worker });

      if (worker.site) {
        // find site index
        const index = state.site.sites.findIndex(e => e.id == worker.site.id);

        // find site and get worker using id
        const site = state.site.sites[index];

        // get worker index and replace it
        const i = site.workers?.findIndex(e => e.id == worker.id);
        site.workers[i] = worker;

        // sort workers
        site.workers = sortWorkersByDate(site.workers);

        // replace
        state.site.site = site;
        state.site.sites[index] = site;

        return {
          ...state,
          site: state.site
        };
      } else {
        return {
          ...state
        };
      }
    }

    case workerActions.UPDATE_WORKER_FAILURE: {
      console.log({ "CROSS => UPDATE_WORKER_FAILURE action :: ": action });
      return {
        ...state
      };
    }

    case missionActions.CREATE_MISSION: {
      console.log({ "CROSS => CREATE_MISSION action :: ": action });
      // get created mission
      const mission = state.mission.mission;
      console.log({ "mission to create :::::::": mission });

      // find site index
      const index = state.site.sites.findIndex(e => e.id == mission.site.id);

      // delete object from mission
      delete mission.site;

      // set author
      mission.author = state.authentication.user;

      // find site and add mission to this site
      const site = state.site.sites[index];
      site.missions?.push(mission);

      // sort missions
      site.missions = sortMissionsByDate(site.missions);

      state.site.site = site;
      state.site.sites[index] = site;

      return {
        ...state,
        site: state.site
      };
    }

    case missionActions.CREATE_MISSION_FAILURE: {
      console.log({ "CROSS => CREATE_MISSION_FAILURE action :: ": action });

      return {
        ...state
      };
    }

    case missionActions.UPDATE_MISSION: {
      console.log({ "CROSS => UPDATE_MISSION action :: ": action });
      // get updated mission
      const mission = state.mission.mission;
      console.log({ "mission to update :::::::": mission });

      // find site index
      const index = state.site.sites.findIndex(e => e.id == mission.site.id);

      if (index >= 0) {
        // find site and get mission using id
        const site = state.site.sites[index];

        // get mission index and replace it
        const i = site.missions?.findIndex(e => e.id == mission.id);
        site.missions[i] = {
          ...site.missions[i],
          temp: mission.temp,
          updated_at: mission.updated_at,
          comment: mission.comment
        };

        // // replace
        state.site.site = site;
        state.site.sites[index] = site;

        return {
          ...state,
          site: state.site
        };
      } else {
        return {
          ...state
        };
      }
    }

    case missionActions.UPDATE_MISSION_FAILURE: {
      console.log({ "CROSS => UPDATE_MISSION_FAILURE action :: ": action });
      return {
        ...state
      };
    }

    case workerActions.UPDATE_CONTROL: {
      console.log({
        "CROSS => UPDATE_CONTROL action :: ": action
      });

      // find site index
      const index = state.site.sites.findIndex(
        e => e.id == action.payload.site.id
      );

      console.log({
        "CROSS => UPDATE_CONTROL == SITES :: ": state.site.sites
      });

      if (index >= 0) {
        // find site and get worker
        const site = state.site.sites[index];
        console.log({
          "CROSS => UPDATE_CONTROL == SITE FOUND :: ": site
        });

        // get worker index
        const i = site.workers?.findIndex(
          e => e.id == action.payload.worker.id
        );

        console.log({
          "CROSS => UPDATE_CONTROL worker index :: ": site.workers
        });

        if (i >= 0) {
          // worker found
          const worker = site.workers[i];
          console.log({
            "CROSS => UPDATE_CONTROL worker found :: ": worker,
            ", controle to found": action.payload.id
          });

          // find worker controles
          const j = worker.controles?.findIndex(c => c.id == action.payload.id);

          console.log({
            "CROSS => UPDATE_CONTROL controle index :: ": j
          });

          if (j >= 0) {
            site.workers[i].controles[j].comment = action.payload.comment;
            site.workers[i].controles[j].validated = action.payload.validated;

            console.log({
              "CROSS => UPDATE_CONTROL OK :: ": site.workers[i].controles[j]
            });
          }

          console.log({ "SITE ::::": site });
          console.log({ "SITES ::::": state.site.sites });

          // replace
          state.site.site = site;
          state.site.sites[index] = site;
        }

        return {
          ...state,
          site: state.site
        };
      } else {
        return {
          ...state
        };
      }
    }

    case workerActions.UPDATE_CONTROL_FAILURE: {
      console.log({ "CROSS => UPDATE_CONTROL_FAILURE action :: ": action });
      return {
        ...state
      };
    }

    case siteActions.GET_SITE: {
      console.log({ "CROSS => GET_SITE action :: ": action });
      return {
        ...state
      };
    }

    case siteActions.GET_SITE_FAILURE: {
      console.log({ "CROSS => GET_SITE_FAILURE action :: ": action });
      return {
        ...state
      };
    }

    default:
      return state;
  }
}

const rootReducer = (state, action) => {
  const intermediateState = appReducer(state, action);
  const finalState = crossSliceReducer(intermediateState, action);
  return finalState;
};

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
