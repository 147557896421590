import { Reducer } from "redux";
import moment from "moment";
import * as actions from "./actionTypes";
import { Worker } from "../../models/Interfaces/Worker";
import { ErrorResponse } from "../../api/error";
import { Control } from "../../models/Interfaces/Control";
import { sortWorkersByName } from "../../utils/Sort";
import { showErrorNotification } from "../../utils/Notification";

export interface WorkerState {
  workers: Worker[];
  worker: Worker | null;
  error: ErrorResponse | null;
}

const initialState: WorkerState = {
  workers: [],
  worker: null,
  error: null
};

const workerReducer: Reducer<WorkerState, actions.WorkerAction> = (
  state: WorkerState = initialState,
  action: actions.WorkerAction
): WorkerState => {
  let workers = state.workers.slice();

  switch (action.type) {
    case actions.GET_WORKERS:
      console.log({ "GET_WORKERS action :: ": action });
      return {
        ...state,
        error: null
      };

    case actions.GET_WORKERS_SUCCESS:
      console.log({ "GET_WORKERS_SUCCESS action :: ": action });
      return {
        ...state,
        workers: sortWorkersByName(action.payload),
        error: null
      };

    case actions.GET_WORKERS_FAILURE:
      console.log({ "GET_WORKERS_FAILURE action :: ": action });
      return {
        ...state,
        error: action.payload.response
      };

    case actions.GET_WORKER:
      console.log({ "GET_WORKER action :: ": action });
      return {
        ...state,
        error: null
      };

    case actions.GET_WORKER_SUCCESS:
      console.log({ "GET_WORKER_SUCCESS action :: ": action });
      return {
        ...state,
        worker: action.payload,
        error: null
      };

    case actions.GET_WORKER_FAILURE:
      console.log({ "GET_WORKER_FAILURE action :: ": action });
      return {
        ...state,
        error: action.payload.response
      };

    case actions.CREATE_WORKER:
      console.log({ "CREATE_WORKER action :: ": action });
      // create a temporary worker
      const worker = {
        ...action.payload.worker,
        id: action.payload.localId,
        created_at: moment().format(),
        updated_at: moment().format(),
        controles: [],
        temp: true
      };

      // push worker in workers array
      workers.push(worker);

      return {
        ...state,
        worker,
        workers: sortWorkersByName(workers),
        error: null
      };

    case actions.CREATE_WORKER_SUCCESS:
      console.log({ "CREATE_WORKER_SUCCESS action ::": action });
      console.log({
        "WORKER CREATED SUCCESSFULLY ::": action.payload
      });

      // filter worker without temporary worker
      workers = workers.filter(value => {
        return value.id !== action.meta.localId;
      });

      // add real worker in workers array
      workers.push(action.payload);

      return {
        ...state,
        worker: action.payload,
        workers: sortWorkersByName(workers),
        error: null
      };

    // server fails to respond
    case actions.CREATE_WORKER_FAILURE:
      console.log({ "CREATE_WORKER_FAILURE action :: ": action });

      if (action.payload.status === 409) {
        showErrorNotification(action.payload.response.message);
      }

      // delete temporary worker from workers array
      workers = workers.filter(value => {
        return value.id !== action.meta.localId;
      });

      return {
        ...state,
        worker: null,
        workers,
        error: action.payload.response
      };

    case actions.UPDATE_WORKER:
      console.log({ "UPDATE_WORKER action ::": action });

      // find worker index
      const index = workers.findIndex(e => e.id == action.payload.id);

      if (workers && workers.length > 0 && index >= 0) {
        // worker found in workers array
        const worker = {
          ...workers[index],
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          btp_number: action.payload.btp_number,
          company: action.payload.company,
          site: action.payload.site,
          btp_card_image: action.payload.btp_card_image,
          updated_at: moment().format(),
          temp: true
        } as Worker;

        // set worker in array to replace it
        workers[index] = worker;

        return {
          ...state,
          worker: workers[index],
          workers: sortWorkersByName(workers),
          error: null
        };
      } else {
        return {
          ...state
        };
      }

    case actions.UPDATE_WORKER_SUCCESS:
      console.log({ "UPDATE_WORKER_SUCCESS action ::": action });
      console.log({
        "WORKER UPDATED SUCCESSFULLY ::": action.payload
      });

      // filter workers without temporary worker
      workers = workers.filter(value => {
        return value.id !== action.meta.id;
      });

      // add real worker in array
      workers.push(action.payload);

      return {
        ...state,
        worker: action.payload,
        workers: sortWorkersByName(workers),
        error: null
      };

    case actions.UPDATE_WORKER_FAILURE:
      console.log({ "UPDATE_WORKER_FAILURE action :: ": action });
      console.log({
        "WORKER UPDATE FAILURE ::": action.payload
      });

      // filter worker without temporary worker
      workers = workers.filter(value => {
        return value.id !== action.meta.id;
      });

      // set old worker
      workers.push(action.meta.worker);

      return {
        ...state,
        worker: action.meta.worker,
        workers: sortWorkersByName(workers),
        error: action.payload.response
      };

    case actions.CREATE_CONTROL:
      console.log({ "CREATE_CONTROL action ::": action });

      // worker controlled
      const w = action.payload.control.worker;

      // find worker index for this control
      const i = workers.findIndex(e => e.id == w.id);

      if (workers && workers.length > 0 && i >= 0) {
        // worker found, get his controles
        const controles = workers[i].controles
          ? workers[i].controles
          : ([] as Control[]);

        // create a temporary control
        const control = {
          ...action.payload.control,
          id: action.payload.localId,
          created_at: moment().format(),
          updated_at: moment().format(),
          temp: true
        } as Control;

        // add temporary control to worker
        controles?.push(control);

        // worker found, add control on worker, and update worker btp_number
        const worker = {
          ...workers[i],
          btp_number: action.payload.control.btp_number,
          updated_at: moment().format(),
          controles: controles,
          temp: true
        } as Worker;

        // set worker to replace it in workers array
        workers[i] = worker;

        return {
          ...state,
          worker: workers[i],
          workers: sortWorkersByName(workers),
          error: null
        };
      } else {
        return {
          ...state
        };
      }

    case actions.CREATE_CONTROL_SUCCESS:
      console.log({ "CREATE_CONTROL_SUCCESS action ::": action });
      console.log({
        "control created ::": action.payload
      });

      console.log({
        "control localId to replace ::": action.meta.localId
      });

      return {
        ...state,
        error: null
      };

    // server fails to respond
    case actions.CREATE_CONTROL_FAILURE:
      console.log({ "CREATE_CONTROL_FAILURE action :: ": action });

      // find worker index
      const l = workers.findIndex(e => e.id == action.meta.control.worker.id);

      if (workers && workers.length > 0 && l >= 0) {
        // filter controles without localId
        const ctrls = workers[l].controles?.filter(
          c => c.id !== action.meta.localId
        );

        // set controles to worker and replace it in array
        workers[l].controles = ctrls;

        return {
          ...state,
          worker: workers[l],
          workers: sortWorkersByName(workers),
          error: action.payload.response
        };
      } else {
        return {
          ...state,
          error: action.payload.response
        };
      }

    case actions.UPDATE_CONTROL:
      console.log({ "UPDATE_CONTROL action ::": action });
      // find worker index
      const j = workers.findIndex(e => e.id == action.payload.worker.id);

      if (workers && workers.length > 0 && j >= 0) {
        // worker found
        const w = workers[j];

        // get worker controls
        const controles = w.controles ? w.controles : ([] as Control[]);

        // find updated control in worker controls
        const k = controles.findIndex(c => c.id == action.payload.id);

        // update control
        const c = {
          ...controles[k],
          comment: action.payload.comment,
          status: action.payload.status,
          updated_at: moment().format(),
          temp: true
        } as Control;

        // set controle to replace controle
        controles[k] = c;

        // update worker with new data,
        const worker = {
          ...w,
          btp_number: action.payload.btp_number,
          controles: controles,
          updated_at: moment().format(),
          temp: true
        } as Worker;

        // set worker in array
        workers[j] = worker;

        return {
          ...state,
          worker: workers[j],
          workers: sortWorkersByName(workers),
          error: null
        };
      } else {
        return {
          ...state
        };
      }

    case actions.UPDATE_CONTROL_SUCCESS:
      console.log({ "UPDATE_CONTROL_SUCCESS action ::": action });

      console.log({
        "control localId to replace ::": action.meta
      });

      return {
        ...state
      };

    case actions.UPDATE_CONTROL_FAILURE:
      console.log({ "UPDATE_CONTROL_FAILURE action ::": action });

      console.log({ "META => ": action.meta });

      // find worker index
      if (action.meta.control && action.meta.control.worker) {
        const m = workers.findIndex(e => e.id == action.meta.control.worker.id);

        if (workers && workers.length > 0 && m >= 0) {
          // filter controles without localId
          const ctrls = workers[m].controles?.filter(
            c => c.id !== action.meta.id
          ) as Control[];

          // add old control
          ctrls.push(action.meta.control);

          // set controles to worker and replace it in array
          workers[m].controles = ctrls;

          return {
            ...state,
            worker: workers[m],
            workers: sortWorkersByName(workers),
            error: action.payload.response
          };
        } else {
          return {
            ...state,
            error: action.payload.response
          };
        }
      } else {
        return {
          ...state,
          error: action.payload.response
        };
      }

    case actions.DELETE_CONTROL:
      console.log({ "DELETE_CONTROL action ::": action });
      const indexOfWorker = workers.findIndex(worker => worker.id == action.payload.worker.id)

      if (workers && workers.length > 0 && indexOfWorker >= 0) {
        const worker = workers[indexOfWorker]

        const controles = worker.controles ? worker.controles : ([] as Control[])

        const indexOfControl = controles.findIndex(control => control.id == action.payload.id)

        if (indexOfControl >= 0) {
          const newControles = controles.filter(control => control.id != action.payload.id)

          worker.controles = newControles
        }

        console.log(worker)

        workers[indexOfWorker] = worker

        return {
          ...state,
          worker: workers[indexOfWorker],
          workers: sortWorkersByName(workers),
          error: null
        };
      } else {
        return {
          ...state
        };
      }

    case actions.DELETE_CONTROL_SUCCESS:
      console.log({ "DELETE_CONTROL_SUCCESS action ::": action });

      return {
        ...state
      };

    case actions.DELETE_CONTROL_FAILURE:
      console.log({ "DELETE_CONTROL_FAILURE action ::": action });

      console.log({ "META => ": action.meta });

      // find worker index
      if (action.meta.control && action.meta.control.worker) {
        const indexOfWorker = workers.findIndex(e => e.id == action.meta.control.worker.id);

        if (workers && workers.length > 0 && indexOfWorker >= 0) {
          // filter controles without localId
          const controles = workers[indexOfWorker].controles?.filter(
            control => control.id !== action.meta.id
          ) as Control[];

          // add old control
          controles.push(action.meta.control);

          // set controles to worker and replace it in array
          workers[indexOfWorker].controles = controles;

          return {
            ...state,
            worker: workers[indexOfWorker],
            workers: sortWorkersByName(workers),
            error: action.payload.response
          };
        } else {
          return {
            ...state,
            error: action.payload.response
          };
        }
      } else {
        return {
          ...state,
          error: action.payload.response
        };
      }

    default:
      return state;
  }
};

export default workerReducer;
