import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import classNames from "classnames";
import { Formik, FormikProps, Field } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../../utils/Notification";
import images from "../../../../images";
import * as workerActions from "../../../../redux/Worker/actions";
import InputText from "../../../Input/Text";
import Autocomplete from "../../../Autocomplete";
import messages from "./messages";
import Button from "../../../Button";
import { Site } from "../../../../models/Interfaces/Site";
import { Mission } from "../../../../models/Interfaces/Mission";
import { Company } from "../../../../models/Interfaces/Company";
import { status as ControlStatus } from "../../../../enums/control";
import ModalCamera from "../../Camera";
import ModalHeader from "../../ModalHeader";
import ModalClose from "../../ModalClose";
import { useThunkDispatch } from "../../../../redux/hooks";
import { trackPromise } from "react-promise-tracker";

interface Props {
  isOpen: boolean;
  onClick: () => void;
  site: Site;
  mission?: Mission;
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#ffffff",
  },
  overlay: {
    zIndex: 9999,
  },
};

const initialValues = {
  firstname: "",
  lastname: "",
  company: "",
  btp: "",
  btpCardNumber: "",
  comment: "",
};

const ModalCreateWorker = (props: Props) => {
  const [dataUri, setDataUri] = useState("");
  const [showModalCamera, setShowModalCamera] = useState(false);
  const [companiesAvailable, setCompaniesAvailable] = useState([] as Company[]);
  const [hasFocusedField, setHasFocusedField] = useState<undefined | boolean>();
  const refForm = useRef<FormikProps<any>>(null);

  useEffect(() => {
    if (props.site) {
      setCompaniesAvailable(props.site?.companies || []);
    }
  }, [props.site]);


  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const validationSchema = Yup.object({
    firstname: Yup.string().required(
      intl.formatMessage(messages.firstNameRequired)
    ),
    lastname: Yup.string().required(
      intl.formatMessage(messages.lastNameRequired)
    ),
    company: Yup.string().required(
      intl.formatMessage(messages.companyNameRequired)
    ),
    btp: Yup.string(), //.required(intl.formatMessage(messages.btpNumberRequired))
  });

  const handleTakePhotoAnimationDone = (dataUri) => {
    console.log({ "dataUri ::": dataUri });
    setDataUri(dataUri);
  };

  const handleCreateWorker = (
    values: {
      firstname: string;
      lastname: string;
      company: string | Company;
      btp: string;
      status: string;
      comment: string;
    },
    setSubmitting: any
  ) => {
    console.log("create worker with control");
    console.log({ "btp_card_image : ": dataUri });

    let payload = {
      firstname: values.firstname,
      lastname: values.lastname,
      company: {
        name: typeof values.company === "string" ? values.company : values.company.name,
      },
      btp_number: values.btp,
      btp_card_image: dataUri,
      site: props.site,
    };

    if (props.mission && values.status) {
      const control = {
        comment: values.comment,
        status: values.status,
        mission: { id: props.mission.id },
        worker: payload,
        site: props.site,
      };
      payload["payload.control"] = control;

      if (navigator.onLine) {
        trackPromise(
          dispatch(workerActions.createWorkerWithControlDispatch(payload, true))
            .then((res) => {
              showSuccessNotification(
                "Le travailleur a été ajouté avec succès"
              );
              props.onClick();
            })
            .catch((err) => {
              showErrorNotification("Le travailleur n'a pas été ajouté");
            })
          // .finally(() => {
          //   props.onClick();
          // })
        );
      } else {
        dispatch(workerActions.createWorkerWithControlDispatch(payload, false));
        props.onClick();
      }
    } else {
      if (navigator.onLine) {
        trackPromise(
          dispatch(workerActions.createWorkerDispatch(payload))
            .then((res) => {
              showSuccessNotification(
                "Le travailleur a été ajouté avec succès"
              );
              props.onClick();
            })
            .catch((err) => {
              showErrorNotification("Le travailleur n'a pas été ajouté");
            })

        );
      } else {
        dispatch(workerActions.createWorkerDispatch(payload));
        props.onClick();
      }
    }

    // reset photo
    setShowModalCamera(false);
    setDataUri("");
  };

  const handleHasFocusedField = () => {
    setHasFocusedField(true);
  };

  const handleHasnotFocusedField = () => {
    setHasFocusedField(false);
  };

  const handleControlChange = (e, setFieldValue) => {
    console.log({ e });
    setFieldValue("status", e, false);
  };

  const handleCommentChange = (e, setFieldValue) => {
    setFieldValue("comment", e.target.value, false);
  };

  const closeModal = <ModalClose onClick={props.onClick} />;

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-worker-new"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <ModalHeader
        sticky
        title={intl.formatMessage(messages.title)}
        closeButton={closeModal}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleCreateWorker(values, setSubmitting);
        }}
        innerRef={refForm}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form
            className="flex flex-col mt-8 px-10 flex-grow bg-white"
            onSubmit={handleSubmit}
            style={{ paddingBottom: "60px" }}
          >
            <div className="flex flex-col mb-1">
              <div
                className="relative flex flex-col items-center justify-center cursor-pointer w-full border-1px border-dashed border-socotec-gray-100 py-10 px-4 bg-socotec-white-100 rounded-20px"
                style={{ minHeight: "175px" }}
                onClick={() => {
                  setShowModalCamera(true);
                }}
              >
                {dataUri && (
                  <div
                    className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-center bg-no-repeat bg-cover rounded-20px"
                    style={{ backgroundImage: `url(${dataUri})` }}
                  >
                    <div
                      className="absolute"
                      style={{ top: "-10px", right: "-10px" }}
                    >
                      <img src={images.retry} alt="Recommencer" />
                    </div>
                  </div>
                )}
                {!dataUri && (
                  <>
                    <img src={images.photo} alt="#" className="mb-4" />
                    <span className="text-socotec-gray-100 text-18px">
                      {intl.formatMessage(messages.takePicture)}
                    </span>
                  </>
                )}
              </div>
              <ModalCamera
                isOpen={showModalCamera}
                onClick={() => setShowModalCamera(false)}
                btpCardNumber={values.btpCardNumber}
                btpNumber={values.btp}
                dataUri={dataUri}
                setDataUri={setDataUri}
                onTakePhoto={handleTakePhotoAnimationDone}
              />
            </div>
            {errors.btp && touched.btp && (
              <div className="text-red-700 font-normal font-roboto italic text-sm mt-1">
                {errors.btp}
              </div>
            )}

            <div className="mb-4" />

            <Autocomplete
              id="company"
              name="company"
              placeholder={intl.formatMessage(messages.company)}
              suggestions={companiesAvailable}
              value={values.company}
              onChange={(value) => {
                setFieldValue("company", value);
              }}
              onFocus={handleHasFocusedField}
              onBlur={handleHasnotFocusedField}
              onFilter={(suggestion) => {
                setFieldValue("company", suggestion)
              }
              }
              keySearch="name"
              className="mb-1 z-2"
            />
            {errors.company && touched.company && (
              <div className="text-red-700 font-normal font-roboto italic text-sm">
                {errors.company}
              </div>
            )}

            <div className="mb-4" />

            <InputText
              id="firstname"
              name="firstname"
              type="text"
              value={values.firstname}
              placeholder={intl.formatMessage(messages.firstname)}
              label={intl.formatMessage(messages.firstname)}
              onChange={handleChange}
              onFocus={handleHasFocusedField}
              onBlur={handleHasnotFocusedField}
              className="mb-1 z-0"
            />
            {errors.firstname && touched.firstname && (
              <div className="text-red-700 font-normal font-roboto italic text-sm">
                {errors.firstname}
              </div>
            )}

            <div className="flex flex-row justify-end mt-1">
              <img
                src={images.echanger}
                alt="Permuter"
                onClick={() => {
                  const currentFirstname = values.firstname;
                  const currentLastname = values.lastname;

                  setFieldValue("firstname", currentLastname);
                  setFieldValue("lastname", currentFirstname);
                }}
              ></img>
            </div>

            <div className="mb-2" />

            <InputText
              id="lastname"
              name="lastname"
              type="text"
              value={values.lastname}
              placeholder={intl.formatMessage(messages.lastname)}
              label={intl.formatMessage(messages.lastname)}
              onChange={handleChange}
              onFocus={handleHasFocusedField}
              onBlur={handleHasnotFocusedField}
              className="mb-1 z-0"
            />
            {errors.lastname && touched.lastname && (
              <div className="text-red-700 font-normal font-roboto italic text-sm">
                {errors.lastname}
              </div>
            )}


            <div className="mb-4" />

            <InputText
              id="btp"
              name="btp"
              type="text"
              value={values.btp}
              placeholder={intl.formatMessage(messages.btpNumber)}
              label={intl.formatMessage(messages.btpNumber)}
              onChange={handleChange}
              onFocus={handleHasFocusedField}
              onBlur={handleHasnotFocusedField}
              className="mb-1 z-1"
            />

            <div className="mb-4" />

            {hasFocusedField !== true && (
              <div
                className="fixed bottom-20px left-10 right-10 z-3000"
                style={{ width: "calc(100% - 5rem)" }}
              >
                <Button
                  disabled={
                    isSubmitting ||
                    !values.firstname ||
                    !values.lastname ||
                    !values.company
                  }
                >
                  <span className="font-roboto">
                    {intl.formatMessage(messages.check)}
                  </span>
                </Button>
              </div>
            )}

            <hr></hr>
            <br />


            {values.company && (
              <div className="flex flex-row space-x-2 text-gray-700 bg-orange-100">
                <img src={images.infosBulle} alt="Info" width="28px" />
                <div>
                  L'entreprise sélectionnée a le statut <strong>{intl.formatMessage(messages[values.company.status || "pending"])}</strong>. Veuillez en tenir compte lors de la validation du contrôle.
                </div>
              </div>
            )}

            <div
              role="group"
              className="flex flex-col flex-wrap content-around justify-center space-y-6 pb-6"
            >
              <Field
                id={ControlStatus.VALIDATED}
                type="radio"
                name="status"
                className="hidden radio-valid"
                value={ControlStatus.VALIDATED}
                onChange={() =>
                  handleControlChange(ControlStatus.VALIDATED, setFieldValue)
                }
              />
              <label
                htmlFor={ControlStatus.VALIDATED}
                className="valid inline-flex justify-between p-3 w-full text-socotec-gray-100 bg-white rounded-lg border hover:text-green-600 hover:bg-green-100"
              >
                Validé
              </label>
              <Field
                id={ControlStatus.APPROUVED}
                type="radio"
                name="status"
                className="hidden radio-approuved"
                value={ControlStatus.APPROUVED}
                onChange={() =>
                  handleControlChange(ControlStatus.APPROUVED, setFieldValue)
                }
              />
              <label
                htmlFor={ControlStatus.APPROUVED}
                className="approuved inline-flex justify-between p-3 w-full text-socotec-gray-100 bg-white rounded-lg border hover:text-orange-600 hover:bg-orange-100"
              >
                En attente d'approbation
              </label>
              <Field
                id={ControlStatus.REFUSED}
                type="radio"
                name="status"
                className="hidden radio-refused"
                value={ControlStatus.REFUSED}
                onChange={() =>
                  handleControlChange(ControlStatus.REFUSED, setFieldValue)
                }
              />
              <label
                htmlFor={ControlStatus.REFUSED}
                className="refused inline-flex justify-between p-3 w-full text-socotec-gray-100 bg-white rounded-lg border hover:text-red-600 hover:bg-red-100"
              >
                Refusé
              </label>
            </div>

            <div className="flex flex-col my-2 bg-socotec-white-100 p-4">
              <div className="flex flex-col relative">
                <div className="text-18px font-bold text-socotec-gray-100 mb-2">
                  Commentaires
                </div>
                <textarea
                  id="comment"
                  name="comment"
                  value={values.comment}
                  placeholder="Je commente le contrôle"
                  onChange={(e) => handleCommentChange(e, setFieldValue)}
                  onFocus={handleHasFocusedField}
                  onBlur={handleHasnotFocusedField}
                  cols={40}
                  rows={5}
                  className="relative w-full font-roboto text-16px text-socotec-gray-100 bg-socotec-white-100 border-none outline-none"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </ReactModal>
  );
};

export default ModalCreateWorker;
