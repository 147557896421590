import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "../../components/Button";
import InputText from "../../components/Input/Text";
import images from "../../images";
import * as authenticationActions from "../../redux/Authentication/actions";
import messages from "./messages";
import { showErrorNotification } from "../../utils/Notification";
import { AppState } from "../../redux/reducers";
import { SignInOpts } from "../../models/Authentication";
import { useThunkDispatch } from "../../redux/hooks";

const initialValues = {
  email: "",
  password: ""
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  const dispatch = useThunkDispatch();
  const history = useHistory();
  const intl = useIntl();

  // pull out state from our store
  const { authenticated, token, user } = useSelector((state: AppState) => ({
    authenticated: state.authentication.authenticated,
    token: state.authentication.token,
    user: state.authentication.user
  }));

  const handleSignIn = (values: SignInOpts, setSubmitting: any) => {
    if (navigator.onLine) {
      setLoading(true);
      dispatch(authenticationActions.signInAndFetchUser(values))
        .then(res => {
          console.log("SignIn Success");
          setLoading(false);
        })
        .catch(err => {
          console.log("SignIn Failure");
          setLoading(false);
          showErrorNotification(
            "Une erreur s'est produite lors de la connexion"
          );
        });
    } else {
      dispatch(authenticationActions.signInAndFetchUser(values));
    }
  };

  const handleTogglePassword = () => {
    console.log({ "hidePassword ::": hidePassword });
    setHidePassword(!hidePassword);
  };

  useEffect(() => {
    console.log({ "authenticated:": authenticated });
    console.log({ "token:": token });
    console.log({ "user:": user });
    if (authenticated && token && user) {
      history.push("/dashboard");
    }
  }, [authenticated, history, token, user]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage(messages.emailInvalid))
      .required(intl.formatMessage(messages.emailRequired)),
    password: Yup.string().required(
      intl.formatMessage(messages.passwordRequired)
    )
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSignIn(values, setSubmitting);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        errors,
        touched
      }) => (
        <div className="flex flex-col w-full min-h-screen bg-socotec-white-100">
          <div className="flex flex-row bg-white justify-center py-16">
            <img src={images.socotec} alt="" />
          </div>
          <div className="flex flex-row px-8 pt-8 justify-center">
            <p className="flex-1 flex flex-col text-center font-roboto tracking-02px text-socotec-gray-100 opacity-100 text-20px">
              {intl.messages["SignInPage.project.name"]}
            </p>
          </div>
          <form
            className="flex flex-col flex-grow justify-between px-8"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col">
              <InputText
                id="email"
                name="email"
                type="email"
                value={values.email}
                placeholder={intl.formatMessage(messages.emailPlaceholder)}
                label={intl.formatMessage(messages.emailLabel)}
                onChange={handleChange}
                className="mt-4"
              />

              {errors.email && touched.email && (
                <div className="mt-2 text-red-700 font-normal font-roboto italic text-sm">
                  {errors.email}
                </div>
              )}

              <InputText
                id="password"
                name="password"
                type={hidePassword ? "password" : "text"}
                value={values.password}
                placeholder={intl.formatMessage(messages.passwordPlaceholder)}
                label={intl.formatMessage(messages.passwordLabel)}
                onChange={handleChange}
                className="mt-8"
                icon={false}
                onClick={handleTogglePassword}
              />

              {errors.password && touched.password && (
                <div className="mt-2 text-red-700 font-normal font-roboto italic text-sm">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="flex flex-col my-8">
              <Button disabled={!values.email || !values.password || loading}>
                <span className="font-roboto">
                  {intl.messages["SignInPage.sign.in"]}
                </span>
              </Button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default injectIntl(Login);
