import * as actions from "./actionTypes";
import { ListSitesOpts, GetSiteOpts } from "../../models/Interfaces/Site";
import { _refreshAccessToken } from "../../utils/Session";

const BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;

export const getSitesDispatch = (payload: ListSitesOpts) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    console.log({ "token :::": token });
    return dispatch(getSites(payload, token));
  };
};

const getSites = (
  payload: ListSitesOpts,
  token: string
): actions.GetSitesAction => {
  return {
    type: actions.GET_SITES,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/sites`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken
        },
        commit: {
          type: actions.GET_SITES_SUCCESS
        },
        rollback: {
          type: actions.GET_SITES_FAILURE
        }
      }
    }
  };
};

export const getSiteDispatch = (payload: GetSiteOpts) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(getSite(payload, token));
  };
};

const getSite = (
  payload: GetSiteOpts,
  token: string
): actions.GetSiteAction => {
  return {
    type: actions.GET_SITE,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/site/${payload.id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken
        },
        commit: {
          type: actions.GET_SITE_SUCCESS,
          meta: { id: payload.id }
        },
        rollback: {
          type: actions.GET_SITE_FAILURE,
          meta: { id: payload.id }
        }
      }
    }
  };
};
