import { v4 as uuidv4 } from "uuid";
import * as actions from "./actionTypes";
import {
  ListWorkerOpts,
  GetWorkerOpts,
  CreateWorkerOpts,
  UpdateWorkerOpts,
  Worker
} from "../../models/Interfaces/Worker";
import {
  CreateControlOpts,
  UpdateControlOpts,
  DeleteControlOpts,
  Control
} from "../../models/Interfaces/Control";
import { getSiteDispatch } from "../Site/actions";
import { _refreshAccessToken } from "../../utils/Session";

const BACKEND_URL = process.env.REACT_APP_API_BACKEND_URL;

export const getWorkersDispatch = (payload: ListWorkerOpts) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(getWorkers(payload, token));
  };
};

const getWorkers = (
  payload: ListWorkerOpts,
  token: string
): actions.GetWorkersAction => {
  return {
    type: actions.GET_WORKERS,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/workers/${payload.site.id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken
        },
        commit: {
          type: actions.GET_WORKERS_SUCCESS,
          meta: { site: payload.site }
        },
        rollback: {
          type: actions.GET_WORKERS_FAILURE,
          meta: { site: payload.site }
        }
      }
    }
  };
};

export const getWorkerDispatch = (payload: GetWorkerOpts) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(getWorker(payload, token));
  };
};

const getWorker = (
  payload: GetWorkerOpts,
  token: string
): actions.GetWorkerAction => {
  return {
    type: actions.GET_WORKER,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/worker/${payload.id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken
        },
        commit: {
          type: actions.GET_WORKERS_SUCCESS,
          meta: { id: payload.id, site: payload.site }
        },
        rollback: {
          type: actions.GET_WORKERS_FAILURE,
          meta: { id: payload.id, site: payload.site }
        }
      }
    }
  };
};

export const createWorkerDispatch = (payload: CreateWorkerOpts) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(createWorker(payload, token)).then(() => {
      // fetch site after created worker
      return dispatch(getSiteDispatch({ id: payload.site.id }));
    });
  };
};

const createWorker = (
  payload: CreateWorkerOpts,
  token: string,
  temporaryId?: string
): actions.CreateWorkerAction => {
  console.log({ "CREATE WORKER": payload });

  const tempId = temporaryId ? temporaryId : uuidv4();

  const body = { ...payload };
  delete body.site;
  const site = JSON.stringify({ id: payload.site.id });
  body.site = JSON.parse(site);

  delete body.controles;

  console.log({ "BODY : ": body });

  return {
    type: actions.CREATE_WORKER,
    payload: { worker: body, localId: tempId },
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/worker`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
          json: body
        },
        commit: {
          type: actions.CREATE_WORKER_SUCCESS,
          meta: { localId: tempId }
        },
        rollback: {
          type: actions.CREATE_WORKER_FAILURE,
          meta: { localId: tempId }
        }
      }
    }
  };
};

export const createWorkerWithControlDispatch = (payload: CreateWorkerOpts, online: boolean) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;

    const control = payload["payload.control"]
    delete payload["payload.control"]

    if (online) {
      return dispatch(createWorker(payload, token)).then((response) => {
        control.worker = {
          id: response.id
        }

        return dispatch(createControlDispatch(control, false))
      })
    } else {
      const tempId = uuidv4()

      control.worker = {
        id: tempId
      }

      dispatch(createWorker(payload, token, tempId))
      dispatch(createControl(control, token))
    }
  }
}

export const updateWorkerDispatch = (
  payload: UpdateWorkerOpts,
  worker: Worker
) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(updateWorker(payload, worker, token)).then(() => {
      // fetch site after updated worker
      return dispatch(getSiteDispatch({ id: payload.site.id }));
    });
  };
};

const updateWorker = (
  payload: UpdateWorkerOpts,
  worker: Worker,
  token: string
): actions.UpdateWorkerAction => {
  console.log({ "UPDATE WORKER": payload });

  const body = { ...payload };
  delete body.site;
  const site = JSON.stringify({ id: payload.site.id });
  body.site = JSON.parse(site);

  delete body.controles;

  console.log({ "BODY : ": body });

  return {
    type: actions.UPDATE_WORKER,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/worker/${payload.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
          json: body
        },
        commit: {
          type: actions.UPDATE_WORKER_SUCCESS,
          meta: { id: payload.id, worker: worker }
        },
        rollback: {
          type: actions.UPDATE_WORKER_FAILURE,
          meta: { id: payload.id, worker: worker }
        }
      }
    }
  };
};

export const createControlDispatch = (
  payload: CreateControlOpts,
  updateWorker: boolean
) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(createControl(payload, token)).then(() => {
      if (updateWorker) {
        const updateWorkerPayload = {
          ...payload.worker,
          btp_number: payload.btp_number,
          btp_card_image: payload.btp_card_image
        } as UpdateWorkerOpts;
        return dispatch(
          updateWorkerDispatch(updateWorkerPayload, payload.worker)
        ).then(() => {
          // get site workers after create control
          return dispatch(
            getWorkersDispatch({
              site: payload.site
            })
          );
        });
      } else {
        // get site workers after create control
        return dispatch(
          getWorkersDispatch({
            site: payload.site
          })
        );
      }
    });
  };
};

const createControl = (
  payload: CreateControlOpts,
  token: string
): actions.CreateControlAction => {
  console.log("ENTRY CREATE_CONTROL");

  const tempId = uuidv4();

  console.log("EXIT CREATE_CONTROL");

  const body = { ...payload };

  //  delete mission
  delete body.mission;
  const mission = JSON.stringify(payload.mission);
  body.mission = JSON.parse(mission);

  //  delete worker
  delete body.worker;

  const worker = payload.worker;
  delete worker.site;

  const controles = worker.controles;

  // delete controles from worker
  delete worker.controles;
  /*controles?.forEach(c => {
    delete c.worker;
  });
  if (controles) worker.controles = JSON.parse(JSON.stringify(controles))*/

  // site
  const site = JSON.stringify({
    id: payload.site.id
  });

  // set site in worker
  worker.site = JSON.parse(site);

  body.worker = JSON.parse(JSON.stringify({ id: worker.id }));

  //  delete site
  delete body.site;
  body.site = JSON.parse(site);

  console.log({ "BODY == ": body });

  const clone = { ...payload };
  const w = clone.worker;
  delete w.controles;
  console.log({
    "CLONE w == ": w
  });
  if (controles) w.controles = JSON.parse(JSON.stringify(controles))
  console.log({
    "CLONE new worker == ": w
  });
  clone.worker = JSON.parse(JSON.stringify(w));
  console.log({
    "CLONE  == ": clone
  });

  return {
    type: actions.CREATE_CONTROL,
    payload: {
      control: clone,
      localId: tempId
    },
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/controle`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
          json: body
        },
        commit: {
          type: actions.CREATE_CONTROL_SUCCESS,
          meta: {
            localId: tempId,
            control: clone
          }
        },
        rollback: {
          type: actions.CREATE_CONTROL_FAILURE,
          meta: {
            localId: tempId,
            control: clone
          }
        }
      }
    }
  };
};

export const updateControlDispatch = (
  payload: UpdateControlOpts,
  control: Control,
  updateWorker: boolean
) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(updateControl(payload, control, token)).then(() => {
      if (updateWorker) {
        const updateWorkerPayload = {
          ...payload.worker,
          btp_number: payload.btp_number,
          btp_card_image: payload.btp_card_image
        } as UpdateWorkerOpts;
        return dispatch(
          updateWorkerDispatch(updateWorkerPayload, payload.worker)
        ).then(() => {
          // get site workers after update control
          return dispatch(
            getWorkersDispatch({
              site: payload.site
            })
          );
        });
      } else {
        // get site workers after update control
        return dispatch(
          getWorkersDispatch({
            site: payload.site
          })
        );
      }
    });
  };
};

const updateControl = (
  payload: UpdateControlOpts,
  control: Control,
  token: string
): actions.UpdateControlAction => {
  console.log("ENTRY UPDATE_CONTROL");
  console.log("EXIT UPDATE_CONTROL");

  const body = { ...payload };

  //  delete mission
  delete body.mission;
  const mission = JSON.stringify(payload.mission);
  body.mission = JSON.parse(mission);

  //  delete worker
  delete body.worker;

  // delete controles from worker
  const worker = payload.worker;
  delete worker.site;

  // const controles = worker.controles;
  // delete worker.controles;
  
  // console.log("🚀 ~ file: actions.ts ~ line 428 ~ controles", controles)
  // controles?.forEach(c => {
  //   delete c.worker;
  // });

  // worker.controles = JSON.parse(JSON.stringify(controles));

  // site
  const site = JSON.stringify({
    id: payload.site.id
  });

  // set site in worker
  worker.site = JSON.parse(site);
  body.worker = payload.worker;

  //  delete site
  delete body.site;
  body.site = JSON.parse(site);

  console.log({ "BODY == ": body });

  return {
    type: actions.UPDATE_CONTROL,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/controle/${payload.id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
          json: body
        },
        commit: {
          type: actions.UPDATE_CONTROL_SUCCESS,
          meta: {
            id: payload.id,
            control: control
          }
        },
        rollback: {
          type: actions.UPDATE_CONTROL_FAILURE,
          meta: {
            id: payload.id,
            control: control
          }
        }
      }
    }
  };
};

export const deleteControlDispatch = (
  payload: DeleteControlOpts,
  control: Control
) => {
  return (dispatch, getState) => {
    const token = getState().authentication.token;
    return dispatch(deleteControl(payload, control, token)).then(() => {
      // get site workers after delete control
      return dispatch(
        getWorkersDispatch({
          site: payload.site
        })
      );
    });
  };
};

const deleteControl = (
  payload: DeleteControlOpts,
  control: Control,
  token: string
): actions.DeleteControlAction => {

  return {
    type: actions.DELETE_CONTROL,
    payload,
    meta: {
      offline: {
        effect: {
          url: `${BACKEND_URL}/controle/${payload.id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`
          },
          refreshAccessToken: _refreshAccessToken,
        },
        commit: {
          type: actions.DELETE_CONTROL_SUCCESS,
          meta: {
            id: payload.id,
            control: control
          }
        },
        rollback: {
          type: actions.DELETE_CONTROL_FAILURE,
          meta: {
            id: payload.id,
            control: control
          }
        }
      }
    }
  }
}