import { Store, createStore, compose, applyMiddleware, AnyAction, Action } from "redux";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { createLogger } from "redux-logger";
import _ from "lodash";
import { createOffline } from "@redux-offline/redux-offline";
import defaultConfig from "@redux-offline/redux-offline/lib/defaults";
import defaultQueue from "@redux-offline/redux-offline/lib/defaults/queue";
import createReduxPromiseListener from "redux-promise-listener";
import rootReducers, { AppState } from "./reducers";
import { SIGN_IN } from "./Authentication/actionTypes";
import { CREATE_WORKER_SUCCESS, CREATE_CONTROL } from "./Worker/actionTypes";
import { CREATE_MISSION_SUCCESS, UPDATE_MISSION } from "./Mission/actionTypes";
import { history } from "../App";
import * as actions from "./Authentication/actionTypes";
import {showErrorNotification} from "../utils/Notification";

const reduxPromiseListener = createReduxPromiseListener();

const discard = (error, _action, _retries) => {
  console.log("Entry discard");
  console.log({
    "error : ": error,
    "action : ": _action,
    "retries : ": _retries
  });

  const { response } = error;
  if (!response) return false; // There was no response
  if (_retries > 0) {
    console.log(
      "Action :",
      _action.type,
      " was already tried 1 times : Discarding it"
    );
    return true;
  } // discard if action retried 3 times

  if (error.status === 500) {
    showErrorNotification("Server Error");
    return true;
  }

  if (error.status === 401 && _action.type !== SIGN_IN) {
    console.log({ "EFFECT ==> ": _action.meta.offline.effect });
    const refreshed = _action.meta.offline.effect.refreshAccessToken(_action);
    console.log({ "REFRESHED ==> ": refreshed });
    return refreshed;
  }

  return error.status >= 400 && error.status < 500;
};

const queue = {
  ...defaultQueue,
  enqueue(outbox, action, { offline }) {
    console.log({
      "outbox: ": outbox,
      ",action: ": action,
      ", offline:": offline
    });
    return [...outbox, action];
  },
  peek(outbox, item, { offline }) {
    console.log({
      "outbox: ": outbox,
      ",item: ": item,
      ", offline:": offline
    });

    if (item && item.type && item.type === CREATE_WORKER_SUCCESS) {
      // old worker id
      const { localId } = item.meta;
      // new worker id
      const { id } = item.payload;
      outbox.forEach(o => {
        if (o && o.type === CREATE_CONTROL) {
          const { payload } = o;
          const { worker } = payload.control;
          if (worker.id === localId) {
            let {
              meta: {
                offline: {
                  effect: {
                    json: { worker }
                  }
                }
              }
            } = o; // extract the config sent to the server

            const stringify: string = JSON.stringify(worker); // stringify it
            const replace: string = _.replace(stringify, localId, id); // replace the temporary id
            worker = JSON.parse(replace); // switch it back to JSON format
            o.meta.offline.effect.json.worker = worker;
          }
        }
      });
    }

    if (item && item.type && item.type === CREATE_MISSION_SUCCESS) {
      // old mission id
      const { localId } = item.meta;
      // new mission id
      const { id } = item.payload;

      // history
      history.push(`/site/${item.payload.site.id}/${id}`);

      outbox.forEach(o => {
        if (o && o.type === UPDATE_MISSION) {
          const { payload } = o;
          if (payload.id === localId) {
            let {
              meta: {
                offline: {
                  effect: { url }
                }
              }
            } = o; // extract the config sent to the server

            const replace: string = _.replace(url, localId, id); // replace the temporary id
            o.meta.offline.effect.url = replace;
          }
        }
      });
    }

    return outbox[0];
  }
};

const offlineConfig = {
  ...defaultConfig,
  returnPromises: true,
  discard: discard,
  queue
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore
} = createOffline({
  ...offlineConfig
});

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
  compose;

const configureStore = (initialState: AppState): Store<AppState> => {
  // logger middleware
  const loggerMiddleware = createLogger();

  // middlewares
  const middlewares = [
    offlineMiddleware,
    thunk,
    reduxPromiseListener.middleware
  ];
  if (process.env.NODE_ENV === `development`) {
    middlewares.push(loggerMiddleware);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    offlineEnhanceReducer(rootReducers),
    initialState,
    composeEnhancer(offlineEnhanceStore, ...enhancers)
  );

  return store;
};

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(initialState);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkDispatch<RootState, unknown, Action>;
export type AppThunkResult<R> = ThunkAction<R, RootState, unknown, Action>;

store.subscribe(() => {
  console.log({ "STORE STATE IN STORE :::::": store.getState() });
});

export const promiseListener = reduxPromiseListener;

export default store;
