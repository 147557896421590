import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import classNames from "classnames"
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import {
  showSuccessNotification,
  showErrorNotification
} from "../../../../utils/Notification";
import images from "../../../../images";
import * as workerActions from "../../../../redux/Worker/actions";
import InputText from "../../../Input/Text";
import Autocomplete from "../../../Autocomplete"
import messages from "./messages";
import Button from "../../../Button";
import { Site } from "../../../../models/Interfaces/Site";
import { Worker } from "../../../../models/Interfaces/Worker";
import { Company } from "../../../../models/Interfaces/Company"
import ModalCamera from "../../Camera";
import ModalHeader from "../../ModalHeader"
import ModalClose from "../../ModalClose"
import { useThunkDispatch } from "../../../../redux/hooks";
import { trackPromise } from "react-promise-tracker";

interface Props {
  isOpen: boolean;
  onClick: () => void;
  site: Site;
  worker: Worker;
  forceOpenCamera: boolean
  setForceOpenCamera: React.Dispatch<React.SetStateAction<boolean>>
}

const customStyles = {
  content: {
    top: 0,
    padding: 0,
    background: "#ffffff"
  },
  overlay: {
    zIndex: 9999
  }
};

const usePrevious = value => {
  const ref = useRef<Worker>()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

const ModalUpdateWorker = (props: Props) => {
  const [dataUri, setDataUri] = useState("");
  const [showModalCamera, setShowModalCamera] = useState(false);
  const [companiesAvailable, setCompaniesAvailable] = useState([] as Company[])
  const [hasFocusedField, setHasFocusedField] = useState<undefined | boolean>()
  const refForm = useRef<FormikProps<any>>(null)

  const dispatch = useThunkDispatch();
  const intl = useIntl();

  const { worker, forceOpenCamera, setForceOpenCamera } = props

  const previousWorker = usePrevious(worker)

  useEffect(() => {
    if (!showModalCamera && forceOpenCamera) {
      setShowModalCamera(true)
    }
  }, [worker, showModalCamera, forceOpenCamera])

  useEffect(() => {
    if (previousWorker && previousWorker?.id != worker.id) {
      setDataUri("")
    }
  }, [worker])

  useEffect(() => {
    if (props.site) {
      const companies = props.site.companies ? props.site.companies : []
      setCompaniesAvailable(companies);
    }
  }, [props.site]);

  const validationSchema = Yup.object({
    firstname: Yup.string().required(
      intl.formatMessage(messages.firstNameRequired)
    ),
    lastname: Yup.string().required(
      intl.formatMessage(messages.lastNameRequired)
    ),
    company: Yup.string().required(
      intl.formatMessage(messages.companyNameRequired)
    ),
    btp: Yup.string() // .required(intl.formatMessage(messages.btpNumberRequired))
  });

  const handleTakePhotoAnimationDone = dataUri => {
    console.log({ "dataUri ::": dataUri });
    setDataUri(dataUri);

    const payload = {
      id: props.worker.id,
      firstname: props.worker.firstname,
      lastname: props.worker.lastname,
      company: {
        name: props.worker.company.name
      },
      btp_number: props.worker.btp_number,
      btp_card_image: dataUri,
      site: props.site
    }

    console.log({ "worker photo to update with ::": payload });

    if (navigator.onLine) {
      dispatch(workerActions.updateWorkerDispatch(payload, props.worker))
        .then(res => {
          showSuccessNotification(
            "Le travailleur a été mis à jour avec succès"
          );
        })
        .catch(err => {
          showErrorNotification("Le travailleur n'a pas été mis à jour");
        });
    } else {
      dispatch(workerActions.updateWorkerDispatch(payload, props.worker));
    }
  };

  const handleUpdateWorker = (
    values: {
      firstname: string;
      lastname: string;
      company: string;
      btp: string;
    },
    setSubmitting: any
  ) => {
    console.log("update worker");
    console.log({ "btp_card_image : ": dataUri });

    const payload = {
      id: props.worker.id,
      firstname: values.firstname,
      lastname: values.lastname,
      company: {
        name: values.company
      },
      btp_number: values.btp,
      btp_card_image: dataUri,
      site: props.site
    };

    console.log({ "worker to update with ::": payload });

    if (navigator.onLine) {
      trackPromise(
      dispatch(workerActions.updateWorkerDispatch(payload, props.worker))
        .then(res => {
          showSuccessNotification(
            "Le travailleur a été mis à jour avec succès"
          );
        })
        .catch(err => {
          console.log("🚀 ~ file: index.tsx ~ line 173 ~ ModalUpdateWorker ~ err", err)
          showErrorNotification("Le travailleur n'a pas été mis à jour");
        })
      );
      props.onClick();
    } else {
      dispatch(workerActions.updateWorkerDispatch(payload, props.worker));
      props.onClick();
    }

    // reset photo
    setShowModalCamera(false)
    setDataUri("")
  };

  const initialValues = {
    firstname: props.worker.firstname,
    lastname: props.worker.lastname,
    company: props.worker.company ? props.worker.company.name : "",
    btp: props.worker.btp_number,
    btpCardNumber: props.worker.btp_card_image
  };

  const worker_btp_card_image = dataUri ? dataUri : worker.btp_card_image

  const handleHasFocusedField = () => {
    setHasFocusedField(true)
  }

  const handleHasnotFocusedField = () => {
    setHasFocusedField(false)
  }

  const closeModal = <ModalClose onClick={props.onClick} />

  const addonRightClassName = classNames("absolute right-20px self-center text-socotec-blue-100 text-15px", {
    "opacity-0": hasFocusedField === undefined,
    "hidden-with-delay": hasFocusedField === false
  })

  const addonRight = <div className={addonRightClassName} onClick={refForm.current?.submitForm}>{intl.formatMessage(messages.save)}</div>

  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="modal-worker-new"
      appElement={document.getElementById("root")}
      style={customStyles}
    >
      <ModalHeader sticky title={intl.formatMessage(messages.title)} closeButton={closeModal} addonRight={addonRight} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleUpdateWorker(values, setSubmitting);
        }}
        innerRef={refForm}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          values,
          errors,
          touched,
          setFieldValue
        }) => (
          <form
            className="flex flex-col mt-5 px-10 flex-grow justify-between bg-white"
            onSubmit={handleSubmit}
            style={{ paddingBottom: "60px" }}
          >
            <div className="flex flex-col my-3">
              <div className="flex flex-col mb-1">
                <div
                  className="relative flex flex-col items-center justify-center cursor-pointer w-full border-1px border-dashed border-socotec-gray-100 py-10 px-4 bg-socotec-white-100 rounded-20px"
                  style={{ minHeight: "175px" }}
                  onClick={() => {
                    setForceOpenCamera(false)
                    setShowModalCamera(true);
                  }}
                >
                  {worker_btp_card_image && (
                    <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-center bg-no-repeat bg-cover rounded-20px" style={{ backgroundImage: `url(${worker_btp_card_image})` }}>
                      <div className="absolute" style={{ top: "-10px", right: "-10px" }}><img src={images.retry} alt="Recommencer" /></div>
                    </div>
                  )}
                  {!worker_btp_card_image && (
                    <>
                      <img src={images.photo} alt="#" className="mb-4" />
                      <span className="text-socotec-gray-100 text-18px">{intl.formatMessage(messages.takePicture)}</span>
                    </>
                  )}
                </div>
                <ModalCamera
                  isOpen={showModalCamera}
                  onClick={() => {
                    setForceOpenCamera(false)
                    setShowModalCamera(false)
                  }}
                  btpCardNumber={values.btpCardNumber}
                  btpNumber={values.btp}
                  dataUri={dataUri}
                  setDataUri={setDataUri}
                  onTakePhoto={handleTakePhotoAnimationDone}
                />
              </div>

              <div className="mb-6" />

              <InputText
                id="firstname"
                name="firstname"
                type="text"
                value={values.firstname}
                placeholder={intl.formatMessage(messages.firstname)}
                label={intl.formatMessage(messages.firstname)}
                onChange={handleChange}
                onFocus={handleHasFocusedField}
                onBlur={handleHasnotFocusedField}
                className="mb-1"
              />
              {errors.firstname && touched.firstname && (
                <div className="text-red-700 font-normal font-roboto italic text-sm">
                  {errors.firstname}
                </div>
              )}

              <div className="flex flex-row justify-end mt-1">
                <img src={images.echanger} alt="Permuter" onClick={() => {
                  const currentFirstname = values.firstname
                  const currentLastname = values.lastname

                  setFieldValue("firstname", currentLastname)
                  setFieldValue("lastname", currentFirstname)
                }}></img>
              </div>

              <div className="mb-2" />

              <InputText
                id="lastname"
                name="lastname"
                type="text"
                value={values.lastname}
                placeholder={intl.formatMessage(messages.lastname)}
                label={intl.formatMessage(messages.lastname)}
                onChange={handleChange}
                onFocus={handleHasFocusedField}
                onBlur={handleHasnotFocusedField}
                className="mb-1"
              />
              {errors.lastname && touched.lastname && (
                <div className="text-red-700 font-normal font-roboto italic text-sm">
                  {errors.lastname}
                </div>
              )}

              <div className="mb-6" />

              <Autocomplete
                id="company"
                name="company"
                placeholder={intl.formatMessage(messages.company)}
                suggestions={companiesAvailable}
                value={values.company}
                onChange={value => setFieldValue('company', value)}
                onFocus={handleHasFocusedField}
                onBlur={handleHasnotFocusedField}
                onFilter={suggestion => setFieldValue('company', suggestion["name"])}
                keySearch="name"
                className="mb-1 z-2"
              />
              {errors.company && touched.company && (
                <div className="text-red-700 font-normal font-roboto italic text-sm">
                  {errors.company}
                </div>
              )}

              <div className="mb-6" />

              <InputText
                id="btp"
                name="btp"
                type="text"
                value={values.btp}
                placeholder={intl.formatMessage(messages.btpNumber)}
                label={intl.formatMessage(messages.btpNumber)}
                onChange={handleChange}
                onFocus={handleHasFocusedField}
                onBlur={handleHasnotFocusedField}
                className="mb-1 z-1"
              />
              {errors.btp && touched.btp && (
                <div className="text-red-700 font-normal font-roboto italic text-sm mt-1">
                  {errors.btp}
                </div>
              )}
            </div>

            {hasFocusedField !== true && (
              <div className="fixed bottom-20px left-10 right-10 z-3000" style={{ width: "calc(100% - 5rem)" }}>
                <Button
                  disabled={
                    isSubmitting ||
                    !values.firstname ||
                    !values.lastname ||
                    !values.company
                  }
                >
                  <span className="font-roboto">
                    {intl.formatMessage(messages.save)}
                  </span>
                </Button>
              </div>
            )}
          </form>
        )}
      </Formik>
    </ReactModal >
  );
};

export default ModalUpdateWorker;
